<template>
  <span
    class="flex flex-row items-center justify-center font-bold text-white rounded-full whitespace-nowrap h-md px-sm md:px-sm gap-xs bg-status-success-base"
  >
    {{
      t('shop.subscription.discount.label', {
        discount: discount,
      })
    }}
  </span>
</template>
<script setup lang="ts">
defineProps({
  discount: {
    type: Number,
    required: true,
  },
});

const { t } = useTrans();
</script>
